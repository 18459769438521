var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("headbox", {
        attrs: { title: "关于我们", bgColor: "#fff", isborder: "true" }
      }),
      _c("h1", [_vm._v("深圳市" + _vm._s(_vm.GLOBAL) + "责任有限公司")]),
      _c("div", { staticClass: "centerbox" }, [
        _c("div", { staticClass: "p" }, [
          _vm._v(
            " 深圳" +
              _vm._s(_vm.GLOBAL) +
              "贵金属责任有限公司是以贵金属交易为主营业务的专业机构，主营“回收提纯”和“兑换销售”两项业务体系。 "
          )
        ]),
        _vm._m(0),
        _c("div", { staticClass: "p" }, [
          _vm._v(
            " 深圳" +
              _vm._s(_vm.GLOBAL) +
              "贵金属责任有限公司经国家商标局批准取得了“" +
              _vm._s(_vm.GLOBAL) +
              "”商标，并将推出“" +
              _vm._s(_vm.GLOBAL) +
              "”为品牌；公司自创立至今，坚持“诚实、诚信、诚恳”的原则。在运营模式上为客户提供更加便捷的服务为根本，经过不断的努力改进 ，形成具有" +
              _vm._s(_vm.GLOBAL) +
              "特色的报价系统和 客户定价、结价、提货和存货的服务模式。针对客户 采用一站式服务，优化交易中的繁琐环节，使客户体验到便捷贴心的服务。 "
          )
        ]),
        _c("div", { staticClass: "p" }, [
          _vm._v(
            " " +
              _vm._s(_vm.GLOBAL) +
              "以成为中国具有行业影响力的贵金属服务企业为宗旨，为国内贵金属销售与回购 打造专业的交易平台，以便捷的交易方式和诚信真实的态度服务于广大的贵金属从业者！ "
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgbox" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/about.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }