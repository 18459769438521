<template>
  <div>
    <headbox title="关于我们" bgColor="#fff" isborder="true"></headbox>
    <h1>深圳市{{GLOBAL}}责任有限公司</h1>
    <div class="centerbox">
      <div class="p">
        深圳{{GLOBAL}}贵金属责任有限公司是以贵金属交易为主营业务的专业机构，主营“回收提纯”和“兑换销售”两项业务体系。
      </div>
      <div class="imgbox">
        <img src="@/assets/images/about.png" alt="" />
      </div>
      <div class="p">
        深圳{{GLOBAL}}贵金属责任有限公司经国家商标局批准取得了“{{GLOBAL}}”商标，并将推出“{{GLOBAL}}”为品牌；公司自创立至今，坚持“诚实、诚信、诚恳”的原则。在运营模式上为客户提供更加便捷的服务为根本，经过不断的努力改进
        ，形成具有{{GLOBAL}}特色的报价系统和
        客户定价、结价、提货和存货的服务模式。针对客户
        采用一站式服务，优化交易中的繁琐环节，使客户体验到便捷贴心的服务。
      </div>
      <div class="p">
        {{GLOBAL}}以成为中国具有行业影响力的贵金属服务企业为宗旨，为国内贵金属销售与回购
        打造专业的交易平台，以便捷的交易方式和诚信真实的态度服务于广大的贵金属从业者！
      </div>
    </div>
  </div>
</template>
<script>
import headbox from "@/components/head.vue";
export default {
  components: {
    headbox
  }
};
</script>
<style lang="scss" scoped="true">
.imgbox {
  width: 80%;
  margin: 0 auto 20px;
}
h1 {
  font-size: 34px;
  text-align: center;
  margin: 40px 0;
}
.centerbox {
  padding: 0 40px;
  .p {
    line-height: 48px;
    font-size: 30px;
    margin-bottom: 40px;
  }
}
</style>
